<template>
    <transition name="loading">
        <Loading v-if='Loading'/>
        <div v-else>
            <!-- <Particle/> -->
            <Show/>
            <Background/>
            <MyLogo/>
            <LinkList/>
            <Sidebar/>
            <Heart :changeLoading="changeLoading"/>
            <Topics/>
            <Footer/>
        </div>
    </transition>
    
</template>

<script>
    import {mapGetters} from 'vuex'
    // import Particle from '@/components/Particle'
    import Show from "@/components/Home/Show";
    import Loading from '@/components/Home/Loading'
    import Background from '@/components/Home/Background'
    import MyLogo from '@/components/MyLogo'
    import LinkList from '@/components/Subpage/LinkList'
    import Sidebar from '@/components/Home/Sidebar'
    import Topics from '@/components/Home/Topics'
    import Heart from '@/components/Home/Heart'
    import Footer from '@/components/Home/Footer'
    export default {
        name:"Home",
        components:{
            // Particle,
            Show,
            Loading,
            Background,
            MyLogo,
            LinkList,
            Sidebar,
            Topics,
            Heart,
            Footer
        },
        data(){
            return{
                Loading:false,
            }
        },
        computed:{
            ...mapGetters('videoInfo',['isInit'])
        },
        methods:{
            changeLoading(){
              this.Loading = true;
              setTimeout(()=>{
                this.Loading = false;
              },1000)
            }
        },
      beforeMount() {
        if(this.isInit){
          this.Loading = true;
          setTimeout(() => {
            this.Loading = false;
          },1500)
        }else{
          this.Loading = false;
        }
      },
    }
</script>

<style>
    .loading-enter-active {
        animation: loading-in 1s ease-in-out both;
    }

    .loading-leave-active {
        animation: loading-in 1s reverse ease-in-out both;
    }

    @keyframes loading-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
</style>