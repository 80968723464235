<template>
  <div class="container">
    <div class="flower" ref="flower"></div>
  </div>
</template>

<script>
export default {
  name: "Show",
  data(){
    return {
      load : 0,
      loader : 10,
      lists:[],
      timer:0
    }
  },
  methods: {
    newXue() {
      const v = {};
      v.wh = Math.random() * 10 + 5;
      const number = window.innerWidth / (window.innerWidth + window.innerHeight);
      if (Math.random() < number) {
        v.x = Math.random() * window.innerWidth;
        v.y = -v.wh;
      } else {
        v.x = window.innerWidth;
        v.y = Math.random() * window.innerHeight;
      }
      v.sdx = Math.random() + 0.3;
      v.sdy = Math.random() + 1;
      v.div = document.createElement("div");
      v.div.className = "home-snow";
      v.div.style.width = v.wh + "px";
      v.div.style.height = v.wh + "px";
      v.div.style.opacity = Math.random() + 0.25
      try {
        this.$refs.flower.appendChild(v.div);
      }catch (e){}
      this.lists.push(v);
    },
    run() {
      this.load++;
      if (this.load === this.loader) {
        this.newXue();
        this.load = 0;
      }
      for (let i = 0; i < this.lists.length; i++) {
        let v = this.lists[i];
        v.x -= v.sdx;
        v.y += v.sdy;
        v.div.style.left = v.x + "px";
        v.div.style.top = v.y + "px";
        if (v.x + v.wh < 0 || v.y > window.innerHeight) {
          this.lists.splice(i, 1);
          try {
            this.$refs.flower.removeChild(v.div);
          }catch (e){}
        }
      }
    },
  },
  mounted() {
    this.timer = setInterval(this.run,10);
  },
  beforeUnmount() {
    clearInterval(this.timer);
    this.lists.splice(0,this.lists.length);
  }
}
</script>

<style>
.home-snow {
  position: absolute;
  background-color: white;
  border-radius: 50%;
  z-index: 10;
}
</style>
<style scoped>
.container{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.flower {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>